<template>
    <div class="animated fadeIn">
        <div>
            <b-card class="piplines-list">
                <b-card-header class="card-header-main">
                    <b-row class="piplines-list-header">
                        <b-col md="2">
                            <project-router-link to="/process/source">
                                <b-button variant="primary" class="text-nowrap">
                                    <i class="icon-plus mr-1"></i>Add new Data Process
                                </b-button>
                            </project-router-link>
                        </b-col>
                    </b-row>
                </b-card-header>
                <b-card-body>
                    <wit-table
                        mode="server"
                        :search="true"
                        :fields="columns"
                        :request-function="requestFunction"
                        striped
                        paginated
                        hide-per-page-selector
                        ref="processTable"
                        :default-per-page="10"
                    >
                        <template v-slot:cell(subtype)="{item}">
                            <img
                                v-if="processTypes[item.subtype]"
                                :src="processTypes[item.subtype].img"
                                class="pipelines-list__img"
                            />
                            {{ item.subtype }}
                        </template>
                        <template v-slot:cell(createdAt)="{item}"> {{ formatDate(item.createdAt) }} </template>
                        <template v-slot:cell(updatedAt)="{item}"> {{ formatDate(item.updatedAt) }} </template>

                        <template v-slot:cell(actions)="{item}">
                            <div class="button-row">
                                <DetailsAction :routerLink="detailsMapper(item)" />
                                <RemoveAction :removeResource="showRemoveModal" :resource="item" />
                            </div>
                        </template>
                    </wit-table>
                </b-card-body>
            </b-card>
        </div>

        <RemoveRecordModal
            v-model="showModal"
            :removeElement="removeProcess"
            :recordId="removeRecordId"
            @close="removeRecordId = null"
        >
            <div slot="error">
                <Feedback :state="processError.state" :invalid="processError.message" />
            </div>
        </RemoveRecordModal>

        <WitModal
            v-model="showWorkflow"
            title="You have created a new Process"
            variant="primary"
            customClass="create-workflow-modal"
            size="md"
        >
            <div class="create-workflow-modal__text">
                If you wish to process this resource or put it on a schedule
                <br />you will need to add it to a workflow or create a new one.
            </div>
            <b-row class="d-block text-center">
                <project-router-link to="/workflow">
                    <b-button id="configure-workflow-button" variant="success" size="md">Configure a workflow</b-button>
                </project-router-link>
                <span class="pad-buttons" />
                <b-button variant="danger" size="md" @click="() => (showWorkflow = !showWorkflow)">Skip</b-button>
            </b-row>
        </WitModal>

        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import DetailsAction from '@/components/Actions/DetailsAction.vue'
import RemoveAction from '@/components/Actions/RemoveAction.vue'
import Feedback from '@/components/Feedback.vue'
import RemoveRecordModal from '@/components/Modals/RemoveRecordModal.vue'
import WitModal from '@/components/Modals/WitModal.vue'
import ReportList from '@/components/ReportList.vue'
import WitTable from '@/components/WitTable.vue'
import Loading from '@/components/loading.vue'
import moment from 'moment'
import VueNotifications from 'vue-notifications'
import {mapGetters} from 'vuex'

import {processTypes} from '@/shared/processTypes'

export default {
    data() {
        return {
            searchUrl: `${process.env.VUE_APP_SEARCH_HOST}/search/process`,
            apiUrl: `${process.env.VUE_APP_NODE_API_HOST}/process`,
            columns: ['name', 'type', 'subtype', 'createdAt', 'updatedAt', 'actions'],
            reloadInterval: null,
            showModal: false,
            showWorkflow: false,
            removeRecordId: null,
            processError: {
                state: null,
                message: null,
            },
        }
    },
    mounted() {
        this.reloadInterval = setInterval(this.reloadTable, 5000)
    },
    components: {
        ReportList,
        Loading,
        DetailsAction,
        RemoveAction,
        RemoveRecordModal,
        Feedback,
        WitModal,
        WitTable,
    },
    notifications: {
        dataImportUpdated: {
            type: VueNotifications.types.success,
            title: 'Process has been successfully updated.',
        },
    },
    created() {
        const {dataImportCreated, dataImportUpdated} = this.$route.query

        if (dataImportCreated) {
            this.$router.replace({query: {}})
            this.showWorkflow = true
        }

        if (dataImportUpdated) {
            this.$router.replace({query: {}})
            this.dataImportUpdated()
        }
    },
    beforeDestroy() {
        clearInterval(this.reloadInterval)
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
        }),
        isViewer() {
            return this.activeProject.role == 'Viewer'
        },
        processTypes() {
            return processTypes.reduce(
                (acc, curr) => ({
                    ...acc,
                    [curr.subtype]: {...curr, img: curr.img ? require(`@/assets/${curr.img}`) : null},
                }),
                {}
            )
        },
    },
    methods: {
        async requestFunction(requestParams) {
            const {query, limit: length, limit, page} = requestParams
            const offset = (page - 1) * length
            const projectId = this.$store.state.project.active.id

            const apiUrl = query ? this.searchUrl : this.apiUrl
            const params = query ? {query, length, offset} : {projectId, ...requestParams}

            try {
                const response = await this.axios.get(apiUrl, {params})

                return {
                    data: response.data.data,
                    count: response.data.metadata.count,
                }
            } catch (error) {
                this.dispatch('error', error)
            }
        },
        detailsMapper(item) {
            if (item.subtype === 'Google Analytics Custom Goals')
                return 'process/customGoals/' + item.pipelineRef.id + '/list'
            else if (item.subtype === 'Google Analytics 4 Cost') return 'process/analytics4Cost/' + item.id
            else if (item.subtype === 'Channel Attribution') return 'process/channelattribution/' + item.id
            else if (item.subtype === 'Google Analytics Goals') return 'process/analyticsgoals/' + item.id
            else if (item.subtype === 'Withook') return 'process/withooks/' + item.id
            else if (item.subtype === 'Analytics Data Import') return 'process/dataimport/' + item.id
            else if (item.subtype === 'Facebook Costs Attribution')
                return `process/joinads/${item.id}?type=${item.subtype}`
            else if (item.subtype === 'Criteo Costs Attribution')
                return `process/joinads/${item.id}?type=${item.subtype}`
            else if (item.subtype === 'Google Ads Costs Attribution')
                return `process/joinads/${item.id}?type=${item.subtype}`
            else if (item.subtype === 'Tradedoubler Costs Attribution')
                return `process/joinads/${item.id}?type=${item.subtype}`
            else if (item.subtype === 'Google Ads Export') return `process/googleadsexport/${item.id}`
            else return 'process/' + item.subtype.toLowerCase().replace(/ /g, '') + '/' + item.id
        },
        reloadTable() {
            if (this.$refs.processTable) this.$refs.processTable.fetchData({silent: true})
        },
        removeProcess() {
            this.processError.state = null
            this.processError.message = null

            this.$store.commit('loading/PROCESSING', `Removing the process...`)
            this.axios
                .delete(`${process.env.VUE_APP_NODE_API_HOST}/process/${this.removeRecordId}`)
                .then(response => {
                    this.reloadTable()

                    this.showModal = false
                    this.$store.commit('loading/PROCESSED')
                })
                .catch(exception => {
                    if (exception.response.status === 409) {
                        const resourcesToDelete = JSON.parse(exception.response.data.data)
                            .map(({name}) => name)
                            .join(', ')
                        this.processError.message = `We cannot delete this resource. We have detected that it occurs on other resources. First, remove it from these places: ${resourcesToDelete}`
                    } else {
                        this.processError.message = exception.response.data.data
                    }
                    this.processError.state = false
                    this.$store.commit('loading/PROCESSED')
                })
        },
        showRemoveModal(process) {
            this.removeRecordId = process.id
            this.processError.state = null
            this.processError.message = null
            this.showModal = true
        },
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD HH:mm:ss')
        },
    },
}
</script>

<style lang="css">
.pipelines-list__img {
    height: 18px;
}
</style>
